import { useEffect, useState } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { useDebounce } from '../../shared/Utils/OtherOperations/useDebounce'
import { defaultMaterialTheme } from '../../shared/Style/Style'
import { StyledSearchBar, SearchBarInput } from './SearchBar.style'

export interface IActiveFilterState {
  searchKey?: string
}

interface ISearchBarProps {
  label: string
  activeFilter: IActiveFilterState
  setActiveFilter(state: IActiveFilterState & Record<string, any>): void
  minCharsLimit?: number
}

export const SearchBar = ({ label, activeFilter, setActiveFilter, minCharsLimit = 2 }: ISearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState('')

  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500)

  useEffect(() => {
    if (searchTerm.length >= minCharsLimit || searchTerm === '') {
      setActiveFilter({
        ...activeFilter,
        searchKey: debouncedSearchTerm,
      })
    }
  }, [debouncedSearchTerm])

  return (
    <StyledSearchBar>
      <ThemeProvider theme={defaultMaterialTheme}>
        <SearchBarInput label={label} value={searchTerm} onChange={({ target: { value } }) => setSearchTerm(value)} />
      </ThemeProvider>
    </StyledSearchBar>
  )
}
