import React, { useState, useEffect, useRef } from "react";

import { Box, IconButton } from "@material-ui/core";
import { Cancel, Delete, Edit, InsertDriveFile, Save, RestorePage, Adb, Apple } from "@material-ui/icons";

import { CustomTableInput } from "../../../shared/CustomTable/CustomTable.style";
import { CustomIconButton } from "../../../shared/Buttons/CustomIconButton";
import { HiddenInput, primary, PrimaryLink, red, secondary } from "../../../shared/Style/Style";

import { SettingsListElement } from "../Settings.style";


interface ISettingsListItemProps {
  item: {
    id: string;
    name: string;
    file?: string;
    fileIOS?: string
    fileAndroid?: string
  };
  updateItem(id: string, name: string, file?: File, fileIOS?: File, fileAndroid?: File): void;
  deleteItem(id: string): void;
  buttonsDisabled: boolean;
  isEditing: {
    itemId: string;
    status: boolean;
  };
  setEditing(state: {
    itemId: string;
    status: boolean;
  }): void;
  withFile?: boolean;
  withMobileSystemFiles?: boolean;
}

export const SettingsListItem = ({
  item,
  updateItem,
  deleteItem,
  buttonsDisabled,
  isEditing,
  setEditing,
  withFile,
  withMobileSystemFiles
}: ISettingsListItemProps) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const androidFileInput = useRef<HTMLInputElement>(null);
  const iosFileInput = useRef<HTMLInputElement>(null);

  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [androidFile, setAndroidFile] = useState(null);
  const [iosFile, setIosFile] = useState(null);

  const cancelHandler = () => {
    setEditing({
      itemId: '',
      status: false,
    })
    setName('');
    setFile(null);
    setIosFile(null);
    setAndroidFile(null);
  }

  useEffect(() => {
    if (isEditing.itemId === item.id && isEditing.status) {
      setName(item.name);
    }
  }, [isEditing.itemId, isEditing.status]);

  useEffect(() => {
    if (isEditing.itemId !== item.id && !isEditing.status) {
      setName('');
      setFile(null);
      setIosFile(null);
      setAndroidFile(null);
    }
  }, [isEditing.itemId, isEditing.status]);

  useEffect(() => {
    if (item) {
      setFile(null);
      setIosFile(null);
      setAndroidFile(null);
    }
  }, [item]);


  return (
    <SettingsListElement
      button={true}
    >
      <Box
        minWidth='100%'
      >
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          flexGrow={1}
        >
          <Box
            mr={2}
          >
            <CustomTableInput
              disabled={isEditing.itemId !== item.id}
              value={(isEditing.itemId === item.id && isEditing.status) ? name : item.name}
              onChange={({ target }) => setName(target.value)}
            />
          </Box>

          <Box>
            <IconButton
              disabled={buttonsDisabled || (isEditing.itemId !== item.id && isEditing.status)}
              onClick={() => {
                if (isEditing.itemId !== item.id && !isEditing.status) {
                  setEditing({
                    itemId: item.id,
                    status: true,
                  });
                  return;
                }

                if (withFile) {
                  updateItem(item.id, name, file);
                  return;
                }

                if (withMobileSystemFiles) {
                  updateItem(item.id, name, iosFile, androidFile);
                  return;
                }

                updateItem(item.id, name)
                }}
            >
              {(isEditing.itemId === item.id && isEditing.status)
                ? <Save />
                : <Edit />
              }
            </IconButton>

            <IconButton
              disabled={buttonsDisabled || (isEditing.itemId !== item.id && isEditing.status)}
              onClick={() => {
                isEditing.status ? cancelHandler() : deleteItem(item.id);
              }}
            >
              {(isEditing.itemId === item.id && isEditing.status)
                ? <Cancel color='secondary' />
                : <Delete color='secondary'/>
              }
            </IconButton>
          </Box>
        </Box>

        {withFile && (
          <>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
            >
              {file?.name || <PrimaryLink href={item.file}>Background</PrimaryLink>}
              <CustomIconButton
                disabled={buttonsDisabled || isEditing.itemId !== item.id || !isEditing.status}
                mainColor={primary}
                disabledColor={secondary}
                callback={() => {
                  fileInput.current.click()
                }}
                children={<RestorePage color='inherit'/>}
              />
            </Box>

            <Box
              position='absolute'
            >
              <HiddenInput
                type="file"
                ref={fileInput}
                disabled={buttonsDisabled}
                onFocus={() => {
                  setFile(null);
                  fileInput.current.value = ""
                }}
                onChange={({ target: { files } }) => setFile(files[0])}
              />
            </Box>
          </>
        )}

        {withMobileSystemFiles && (
          <>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
            >
              <Box>
                {iosFile?.name || <PrimaryLink href={item.fileIOS}>IOS background</PrimaryLink>}
                <CustomIconButton
                  disabled={buttonsDisabled || isEditing.itemId !== item.id || !isEditing.status}
                  mainColor={primary}
                  disabledColor={secondary}
                  callback={() => {
                    iosFileInput.current.click()
                  }}
                  children={<Apple color='inherit'/>}
                />
              </Box>

              <Box>
                {androidFile?.name || <PrimaryLink href={item.fileAndroid}>Android background</PrimaryLink>}
                <CustomIconButton
                  disabled={buttonsDisabled || isEditing.itemId !== item.id || !isEditing.status}
                  mainColor={primary}
                  disabledColor={secondary}
                  callback={() => {
                    androidFileInput.current.click()
                  }}
                  children={<Adb color='inherit'/>}
                />
              </Box>
            </Box>

            <Box
              position='absolute'
            >
              {['androidFile', 'iosFile'].map(fileType => (
                <HiddenInput
                  type="file"
                  ref={fileType === 'iosFile' ? iosFileInput : androidFileInput}
                  disabled={buttonsDisabled}
                  onFocus={() => {
                    if (fileType === 'iosFile') {
                      setIosFile(null);
                      iosFileInput.current.value = ""
                      return;
                    }
                    setAndroidFile(null);
                    androidFileInput.current.value = ""
                  }}
                  onChange={({ target: { files } }) => fileType === 'iosFile'
                    ? setIosFile(files[0])
                    : setAndroidFile(files[0])
                  }
                />
              ))}
            </Box>
          </>
        )}
      </Box>
    </SettingsListElement>
  )
}