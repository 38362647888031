import React, { useState, useEffect, useRef } from "react";

import { Box, Collapse } from "@material-ui/core";
import {
  Adb,
  AttachFile,
  Delete,
  InsertDriveFile,
  Apple,
  Dock,
  DockOutlined,
  DockSharp,
  FileCopy, FileCopyTwoTone
} from "@material-ui/icons";

import { CustomIconButton } from "../../../shared/Buttons/CustomIconButton";
import {
  ButtonCustom,
  HiddenInput,
  TextFieldStyled,
} from "../../../shared/Style/Style";

import { Text, Title, TitleContainer } from "../Settings.style";

interface ISettingsCreateItemFormProps {
  createItem(name: string, file?: File, fileIOS?: File, fileAndroid?: File, manifestIOS?:File, manifestAndroid?:File): void;
  setCreating(state: boolean): void;
  inputError: boolean;
  setInputError(state: boolean): void;
  header: string;
  areButtonsDisabled: boolean;
  isRequestSuccessful: boolean;
  withFile?: boolean;
  withMobileSystemFiles?: boolean;
}

export const SettingsCreateItemForm = ({
  withFile,
  withMobileSystemFiles,
  createItem,
  setCreating,
  inputError,
  setInputError,
  isRequestSuccessful,
  header,
  areButtonsDisabled
}: ISettingsCreateItemFormProps) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const androidFileInput = useRef<HTMLInputElement>(null);
  const iosFileInput = useRef<HTMLInputElement>(null);
  const androidManifestFileInput = useRef<HTMLInputElement>(null);
  const iosManifestFileInput = useRef<HTMLInputElement>(null);


  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [androidFile, setAndroidFile] = useState(null);
  const [iosFile, setIosFile] = useState(null);
  const [androidManifestFile, setAndroidManifestFile] = useState(null);
  const [iosManifestFile, setIosManifestFile] = useState(null);

  useEffect(() => {
    if (isRequestSuccessful) {
      setName('');

      if (withFile) {
        setFile(null);
        fileInput.current.value = "";
      }

      if (withMobileSystemFiles) {
        setAndroidFile(null);
        setIosFile(null);
        androidFileInput.current.value = "";
        iosFileInput.current.value = "";
        if(!!androidManifestFile) {
          setAndroidManifestFile(null);
          androidManifestFileInput.current.value = "";
        }
        if(!!iosManifestFile){
          setIosManifestFile(null);
          iosManifestFileInput.current.value = "";
        }
      }
    }
  }, [isRequestSuccessful])

  return (
    <TitleContainer>
      <Title>
        <Text>{header}</Text>

        <ButtonCustom
          isActive
          disabled={areButtonsDisabled}
          width={"169"}
          onClick={() => {
            if (withFile) {
              createItem(name, file);
              return;
            }

            if (withMobileSystemFiles) {
              createItem(name, iosFile, androidFile, iosManifestFile ,androidManifestFile);
              return;
            }

            createItem(name)
          }}
        >
          Добавить
        </ButtonCustom>
      </Title>

      <div>
        <TextFieldStyled
          disabled={areButtonsDisabled}
          margin="normal"
          type="text"
          error={inputError}
          value={name}
          onFocus={() => setCreating(true)}
          onChange={({ target }) => {
            setInputError(false);
            setName(target.value);
          }}
          helperText={inputError ? 'Введите название' : ''}
          fullWidth
          InputProps={{
            endAdornment: (
              <>
                {withMobileSystemFiles && (
                  <Box
                    display='flex'
                  >
                    <CustomIconButton
                      disabled={areButtonsDisabled || file}
                      callback={() => iosFileInput.current.click()}
                      children={<Apple />}
                      fz={8}
                    />

                    <CustomIconButton
                      disabled={areButtonsDisabled || file}
                      callback={() => androidFileInput.current.click()}
                      children={<Adb />}
                      fz={8}
                    />

                    <CustomIconButton
                      disabled={areButtonsDisabled || file}
                      callback={() => iosManifestFileInput.current.click()}
                      children={<FileCopyTwoTone />}
                      fz={8}
                    />

                    <CustomIconButton
                      disabled={areButtonsDisabled || file}
                      callback={() => androidManifestFileInput.current.click()}
                      children={<FileCopy />}
                      fz={8}
                    />
                  </Box>
                )}

                {withFile && (
                  <CustomIconButton
                    disabled={areButtonsDisabled || file}
                    callback={() => fileInput.current.click()}
                    children={<AttachFile />}
                    fz={8}
                  />
                )}
              </>
            )
          }}
        />

        {withFile && (
          <Collapse
            in={!!file}
          >
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <InsertDriveFile />
              {file?.name || ""}
              <CustomIconButton
                disabled={areButtonsDisabled}
                callback={() => {
                  setFile(null);
                  fileInput.current.value = ""
                }}
                children={<Delete color='secondary' />}
              />
            </Box>

            <Box
              position='absolute'
            >
              <HiddenInput
                id="androidInput"
                type="file"
                ref={fileInput}
                disabled={areButtonsDisabled}
                onFocus={() => (fileInput.current.value = "")}
                onChange={({ target: { files } }) => setFile(files[0])}
              />
            </Box>
          </Collapse>
        )}

        {withMobileSystemFiles && (
          <>
            <Collapse
              in={!!androidFile}
            >
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Adb />
                {androidFile?.name || ""}
                <CustomIconButton
                  disabled={areButtonsDisabled}
                  callback={() => {
                    setAndroidFile(null);
                    androidFileInput.current.value = ""
                  }}
                  children={<Delete color='secondary' />}
                />
              </Box>

              <Box
                position='absolute'
              >
                <HiddenInput
                  id="androidInput"
                  type="file"
                  ref={androidFileInput}
                  disabled={areButtonsDisabled}
                  onFocus={() => (androidFileInput.current.value = "")}
                  onChange={({ target: { files } }) => setAndroidFile(files[0])}
                />
              </Box>
            </Collapse>

            <Collapse
              in={!!iosFile}
            >
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <Apple />
                {iosFile?.name || ""}
                <CustomIconButton
                  disabled={areButtonsDisabled}
                  callback={() => {
                    setIosFile(null);
                    iosFileInput.current.value = ""
                  }}
                  children={<Delete color='secondary' />}
                />
              </Box>

              <Box
                position='absolute'
              >
                <HiddenInput
                  type="file"
                  ref={iosFileInput}
                  disabled={areButtonsDisabled}
                  onFocus={() => (iosFileInput.current.value = "")}
                  onChange={({ target: { files } }) => setIosFile(files[0])}
                />
              </Box>
            </Collapse>

            <Collapse
              in={!!iosManifestFile}
            >
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <FileCopyTwoTone />
                {iosManifestFile?.name || ""}
                <CustomIconButton
                  disabled={areButtonsDisabled}
                  callback={() => {
                    setIosManifestFile(null);
                    iosManifestFileInput.current.value = ""
                  }}
                  children={<Delete color='secondary' />}
                />
              </Box>

              <Box
                position='absolute'
              >
                <HiddenInput
                  type="file"
                  ref={iosManifestFileInput}
                  disabled={areButtonsDisabled}
                  onFocus={() => (iosManifestFileInput.current.value = "")}
                  onChange={({ target: { files } }) => setIosManifestFile(files[0])}
                />
              </Box>
            </Collapse>

            <Collapse
              in={!!androidManifestFile}
            >
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
              >
                <FileCopy />
                {androidManifestFile?.name || ""}
                <CustomIconButton
                  disabled={areButtonsDisabled}
                  callback={() => {
                    setAndroidManifestFile(null);
                    androidManifestFileInput.current.value = ""
                  }}
                  children={<Delete color='secondary' />}
                />
              </Box>

              <Box
                position='absolute'
              >
                <HiddenInput
                  type="file"
                  ref={androidManifestFileInput}
                  disabled={areButtonsDisabled}
                  onFocus={() => (androidManifestFileInput.current.value = "")}
                  onChange={({ target: { files } }) => setAndroidManifestFile(files[0])}
                />
              </Box>
            </Collapse>

          </>
        )}
      </div>
    </TitleContainer>
  );
}
