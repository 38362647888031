import {
  ApplicationBundleTypeEnum,
  ExperimentTypeEnum,
  LessonOrUserLibraryContent,
} from "../../../generated/graphql";
import { ExperimentsTypes } from "./types";

export const maxTitleSymbols = 255;

export const maxDescriptionSymbols = 1500;

export const bundleTypeItems = [
  {
    label: "Бандл",
    entity: ApplicationBundleTypeEnum.Bundle,
    field: "bundleType",
  },
  {
    label: "Ссылка",
    entity: ApplicationBundleTypeEnum.BundleLink,
    field: "bundleLink",
  },
  {
    label: "Картинка",
    entity: ApplicationBundleTypeEnum.Photo,
    field: "bundlePhoto",
  },
];

export const surveyLabels = [
  { label: "Показывать", entity: true },
  { label: "Не показывать", entity: false },
];

export const markerLabels = [
  { label: "Есть", entity: true },
  { label: "Нет", entity: false },
];

export const LoaderProps = {
  style: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden",
  },
};

export enum ModelFields {
  IsMarker = "isMarker",
  MarkerPhoto = "markerPhoto",
  Photo = "photo",
  IsMap = "isMap",
  MapMarkerPhoto = "mapMarkerPhoto",
  BundleType = "bundleType",
  BundlePhoto = "bundlePhoto",
  BundleLink = "bundleLink",
  Bundles = "bundles",
  SmartNotificationId = "smartNotificationId",
  ContentPreviews = "contentPreviews",
  ColoringPageFile = "coloringPageFile",
  ExperimentType = "experimentType",
  Tags = "tags",
}

export const mediaCardsQuantity = 6;

export const regexYouTubeUrl =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;

export const videoIdGroupIndex = 5;

export const maxImageSize = 10 * 1024 * 1024;

export const aspectRatio = 16 / 9;

export const qualityArgument = 1;

export enum BundleNames {
  Ios = "IOS",
  WebGL = "WebGL",
  Android = "Android",
  Vr = "VR эксперимент",
  IosManifest = "IOS manifest",
  WebGlManifest = "WebGl manifest",
  AndroidManifest = "Android manifest",
  VrManifest = "VR эксперимент manifest",
  ColoringBookAR = "Раскраска AR",
}

export enum BundleMode {
  Manifest = "manifest",
  Bundle = "bundle",
}

export const experiments: ExperimentsTypes[] = [
  {
    type: ExperimentTypeEnum.ArWebgl,
    text: "AR/3D експеримент",
  },
  {
    type: ExperimentTypeEnum.Vr,
    text: "VR експеримент",
  },
  {
    type: ExperimentTypeEnum.ColoringPageAr,
    text: "Розмальовка AR",
  },
  {
    type: ExperimentTypeEnum.InteractiveExercise,
    text: "Інтерактивна вправа",
  },
];

export const SUBJECT_QUANTITY = 100;

export const requiredFields = [
  "name",
  "description",
  "experimentType",
  "mains",
  "schoolGrades",
  "tags",
  "contentPreviews",
  "libraryContent",
];

export const invalidFileExtensions = [
  ".xbm",
  ".avif",
  ".pjp",
  ".apng",
  ".jfif",
  ".pjpeg",
  ".gif",
];

export const validImageTypes = ["image/jpeg", "image/png"];

export const acceptedColoringPageFileExtension = ".jpg, .jpeg, .png, .pdf";

export const showUploadFileMessage =
  "Файл успешно прикреплен и будет доступен после сохранения";
export const coloringPageFileSize = 30;
export const wrongFileExtensionMessage = `Неверный формат файла. Допустимые форматы: ${acceptedColoringPageFileExtension}`;
export const wrongFileSizeMessage = `Недопустимый размер файла: Лимит размера: ${coloringPageFileSize} МБ`;
export const wrongEqualIdMessage = `Неверный формат equalId`;

export const ContentTagByContentType = new Map<ExperimentTypeEnum, LessonOrUserLibraryContent>([
  [ExperimentTypeEnum.ArWebgl, LessonOrUserLibraryContent.Experiment],
  [ExperimentTypeEnum.Vr, LessonOrUserLibraryContent.ExperimentVr],
  [ExperimentTypeEnum.ColoringPageAr, LessonOrUserLibraryContent.ColoringPageAr],
  [ExperimentTypeEnum.InteractiveExercise, LessonOrUserLibraryContent.InteractiveExercise],
]);
