import { gql } from "@apollo/client";

export const GET_APPLICATIONS_MAIN_MODELS = gql`
  query getApplicationMainModels(
    $skip: Int
    $limit: Int
    $applicationMainId: String
    $isPublished: Boolean
    $themeId: String
    $search: String
    $sort: SortTypeEnum
    $order: OrderTypeEnum
  ) {
    getApplicationMainModels(
      skip: $skip
      limit: $limit
      applicationMainId: $applicationMainId
      isPublished: $isPublished
      themeId: $themeId
      search: $search
      sort: $sort
      order: $order
    ) {
      models {
        id
        name
        description
        photo
        isPublished
        smartNotificationId
        marketId
        isUploadedToMarket
      }
      total
    }
  }
`;

export const GET_APPLICATIONS_MAIN_MODELS_SELECT = gql`
  query getApplicationMainModelsSelect(
    $skip: Int
    $limit: Int
    $applicationMainId: String!
    $isPublished: Boolean
  ) {
    getApplicationMainModels(
      skip: $skip
      limit: $limit
      applicationMainId: $applicationMainId
      isPublished: $isPublished
    ) {
      models {
        id
        name
      }
    }
  }
`;

export const GET_APPLICATION_MAIN_MODEL = gql`
  query getApplicationMainModel($id: String!) {
    getApplicationMainModel(id: $id) {
      id
      marketId
      name
      description
      experimentType
      isUploadedToMarket
      lat
      longitude
      address
      mapMarkerPhoto
      photo
      isBundle
      childId
      bundles {
        id
        type
        language
        file
        manifest
      }
      schoolGrade {
        id
        name
      }
      schoolGradeId
      inGradeOrder
      subscriptionPlan
      bundleType
      bundlePhoto
      bundleLink
      iosBundle
      vrBundle
      androidBundle
      isMarker
      markerWidth
      markerPhoto
      isMap
      isPublished
      isContour
      themeId
      smartNotificationId
      showSmartNotification
      clicks
      contentAuthor
      schoolGrades {
        id
        name
      }
      educationAreas {
        id
        name
        createdDate
      }
      main {
        id
        name
      }
      mains {
        id
        name
      }
      contentPreviews {
        id
        url
        type
        order
      }
      tags {
        id
        name
        displayName
      }
      coloringPageFile
      libraryContent {
        equalId
        price
      }
    }
  }
`;

export const GET_EDUCATION_AREAS = gql`
  query GetEducationAreas {
    getEducationAreas {
      id
      name
    }
  }
`;
export const GET_APPLICATIONS_MAIN_FOR_SELECT = gql`
  query getApplicationsMainForSelect(
    $skip: Int
    $limit: Int
    $applicationId: String!
    $isPublished: Boolean
  ) {
    getApplicationsMain(
      skip: $skip
      limit: $limit
      applicationId: $applicationId
      isPublished: $isPublished
    ) {
      applicationsMain {
        id
        name
      }
    }
  }
`;

export const GET_CONTENT_TAG_BY_CONTENT_TYPE = gql`
  query getContentTagByContentType(
    $contentType: [LessonOrUserLibraryContent!]!
  ) {
    getContentTagByContentType(contentType: $contentType) {
      id
      name
      displayName
    }
  }
`;
