import { gql } from "@apollo/client";

export const CREATE_APPLICATION_MAIN_MODEL = gql`
  mutation createApplicationMainModel(
    $modelData: CreateApplicationMainModelInput!
  ) {
    createApplicationModel(modelData: $modelData) {
      id
    }
  }
`;

export const UPDATE_APPLICATION_MAIN_MODEL = gql`
  mutation updateApplicationMainModel(
    $modelData: UpdateApplicationMainModelInput!
  ) {
    updateApplicationModel(modelData: $modelData) {
      id
    }
  }
`;

export const DELETE_APPLICATION_MAIN_MODEL = gql`
  mutation deleteApplicationMainModel($id: String!) {
    deleteApplicationModel(id: $id)
  }
`;

export const PUBLISH_APPLICATION_MAIN_MODEL = gql`
  mutation publishApplicationMainModel($id: String!) {
    publishApplicationMainModel(id: $id)
  }
`;

export const CREATE_CONTENT_PREVIEW = gql`
  mutation createContentPreview(
    $contentLink: ContentLinkInput!
    $file: Upload
    $videoUrl: String
  ) {
    createContentPreview(
      contentLink: $contentLink
      file: $file
      videoUrl: $videoUrl
    ) {
      id
    }
  }
`;

export const UPDATE_CONTENT_PREVIEW_ORDER = gql`
  mutation updateContentPreviewOrder(
    $updateContentPreviewOrderId: String!
    $order: Int!
  ) {
    updateContentPreviewOrder(id: $updateContentPreviewOrderId, order: $order)
  }
`;

export const DELETE_CONTENT_PREVIEW = gql`
  mutation deleteContentPreview($deleteContentPreviewId: String!) {
    deleteContentPreview(id: $deleteContentPreviewId)
  }
`;

export const SET_APPLICATION_MAIN_MODEL_EXPERIMENT_TYPE = gql`
  mutation setApplicationMainModelExperimentType(
    $setApplicationMainModelExperimentTypeId: String!
    $experimentType: ExperimentTypeEnum!
  ) {
    setApplicationMainModelExperimentType(
      id: $setApplicationMainModelExperimentTypeId
      experimentType: $experimentType
    )
  }
`;
