import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { UserFields } from "../Style";
import {
  AddSubjectToTeacherDocument,
  AddSubjectToTeacherMutation,
  ApplicationMain,
  GetApplicationsMainDocument,
  GetApplicationsMainQuery,
  GetUserAppDocument,
  RemoveSubjectFromTeacherDocument,
  RemoveSubjectFromTeacherMutation,
} from "../../../generated/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Checkbox, Chip, CircularProgress } from "@material-ui/core";
import { LoadingBox } from "../../shared/Style/Style";

interface IUserSubject {
  subjects: ApplicationMain[];
  applicationId: string;
  userId: string;
  setRequestError(error: boolean): void;
}

export const UserSubjectsSelect = ({
  subjects,
  applicationId,
  userId,
  setRequestError,
}: IUserSubject) => {
  const {
    data: getApplicationsMainData,
    loading: getApplicationsMainLoading,
    error: getApplicationsMainError,
  } = useQuery<GetApplicationsMainQuery>(GetApplicationsMainDocument, {
    variables: { applicationId, isPublished: true },
  });
  const [
    appSubject,
    {
      data: appSubjectData,
      loading: appSubjectLoading,
      error: appSubjectError,
    },
  ] = useMutation<AddSubjectToTeacherMutation>(AddSubjectToTeacherDocument, {
    refetchQueries: [
      {
        query: GetUserAppDocument,
        variables: { userId },
      },
      {
        query: GetApplicationsMainDocument,
        variables: { applicationId },
      },
    ],
  });

  const [
    removeSubject,
    {
      data: removeSubjectData,
      loading: removeSubjectLoading,
      error: removeSubjectError,
    },
  ] = useMutation<RemoveSubjectFromTeacherMutation>(
    RemoveSubjectFromTeacherDocument,
    {
      refetchQueries: [
        {
          query: GetUserAppDocument,
          variables: { userId },
        },
        {
          query: GetApplicationsMainDocument,
          variables: { applicationId },
        },
      ],
    }
  );

  const addSubjectHandle = async (appMainId: string, userId: string) => {
    try {
      await appSubject({
        variables: {
          appMainId,
          userId,
        },
      });
    } catch (e) {
      setRequestError(true);
    }
  };
  const removeSubjectHandle = async (appMainId: string, userId: string) => {
    try {
      await removeSubject({
        variables: {
          appMainId,
          userId,
        },
      });
    } catch (e) {
      setRequestError(true);
    }
  };

  if (getApplicationsMainLoading) {
    return (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  const fetchedSubjects =
    getApplicationsMainData?.getApplicationsMain.applicationsMain || [];

  return (
    <Autocomplete
      multiple
      fullWidth
      filterSelectedOptions
      onChange={(e, value) => addSubjectHandle(value[value.length - 1], userId)}
      options={fetchedSubjects?.map((sub) => sub.id)}
      getOptionLabel={(option) =>
        fetchedSubjects?.find(({ id }) => id === option)?.name || ""
      }
      value={subjects?.map(({ id }) => id)}
      disabled={appSubjectLoading || !!appSubjectError}
      renderTags={(value: readonly string[], getTagProps) => {
        return value.map((option: string, index: number) => {
          return (
            <Chip
              variant="outlined"
              label={fetchedSubjects?.find(({ id }) => id === option)?.name}
              {...getTagProps({ index })}
              onDelete={(e) => removeSubjectHandle(option, userId)}
            />
          );
        });
      }}
      renderInput={(params) => (
        <UserFields {...params} label="Предметы" variant="standard" />
      )}
    />
  );
};
