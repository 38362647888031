import { validate as validateUUID } from "uuid";
import {
  ApplicationMainModel,
  ApplicationMainModelFile,
  ApplicationMainModelFileTypeEnum,
  ContentTag,
  ExperimentTypeEnum,
  GetApplicationMainModelDocument,
  LanguagesEnum,
  LessonOrUserLibraryContent as ContentType,
  SubscriptionPlanStatusEnum,
} from "../../../generated/graphql";
import {
  acceptedColoringPageFileExtension,
  BundleMode,
  BundleNames,
  coloringPageFileSize,
  invalidFileExtensions,
  maxImageSize,
  maxTitleSymbols,
  requiredFields,
  validImageTypes,
  wrongEqualIdMessage,
  wrongFileExtensionMessage,
  wrongFileSizeMessage,
} from "./constants";
import { mobileAppModel } from "./state";
import { coordsCheckHandler } from "../../shared/Utils/CheckOperations/coordsCheckHandler";
import { validateForm } from "../../School/Purchases/Purchase/utils";
import { PurchaseFormValues } from "../../School/Purchases/Purchase/types";
import { MAX_GOODS_PRICE } from "../../School/Purchases/Purchase/constants";

export const currentBundleFindHandler = (
  bundles: ApplicationMainModelFile[],
  type: ApplicationMainModelFileTypeEnum,
  language: LanguagesEnum
): ApplicationMainModelFile => {
  return bundles.find(
    (bundle) =>
      bundle.type === type &&
      (bundle.language as unknown as LanguagesEnum) === language
  );
};

export const getRefetchModelQueries = (id: string) => [
  { query: GetApplicationMainModelDocument, variables: { id } },
];

export const getTextHelperTitle = (title: string) => {
  const isTitleOverflow = title.length > maxTitleSymbols;
  if (isTitleOverflow) {
    return `Для повного відображення назви контенту у додатку, рекомендована кількість символів до ${maxTitleSymbols}`;
  }
};

export const getTextHelperEqualId = (value: string) => {
  if (!validateUUID(value)) return wrongEqualIdMessage;
};

export const isRequiredFieldEmpty = (
  value: string | null | [] | { equalId: string }
) => {
  if (Array.isArray(value)) return !value.length;

  if (typeof value === "string") return !value;

  if (typeof value === "object")
    return !value?.equalId.trim().length || !validateUUID(value?.equalId);
};

export const validateInputs = (
  model: ApplicationMainModel,
  language: LanguagesEnum
) => {
  const invalidCoords =
    model?.lat &&
    model?.longitude &&
    !coordsCheckHandler(`${model?.lat}, ${model?.longitude}`);
  let isEmptyFields = false;

  const { libraryContent, subscriptionPlan } = model;
  const isPurchases = subscriptionPlan === SubscriptionPlanStatusEnum.Purchases;
  const { price } = libraryContent;

  for (const field of requiredFields) {
    if (isRequiredFieldEmpty(model[field])) {
      mobileAppModel.setInputErrors(field, true);
      isEmptyFields = true;
    }
  }

  if (!language) {
    mobileAppModel.setInputErrors("language", true);
    isEmptyFields = true;
  }

  if (isEmptyFields) {
    mobileAppModel.setError(true, `Заполните все обязательные поля`);
    return false;
  }

  if (invalidCoords) {
    mobileAppModel.setError(true, "Неверные координаты");
    return false;
  }

  if (isPurchases) {
    const [hasError] = validatePrice(String(price));
    mobileAppModel.setError(hasError, "Неверно заполено поле Стоимость");
    return !hasError;
  }

  return true;
};

export const getFieldsToUpdate = (
  fields: string[],
  currentModel: ApplicationMainModel
) => {
  const fieldsToUpdate = fields
    .filter((key) => key in currentModel)
    .reduce((field, key) => ({ ...field, [key]: currentModel[key] }), {});

  return fieldsToUpdate;
};

export const getEmbedUrl = (videoID: string) => {
  return `https://www.youtube.com/embed/${videoID}`;
};

export const imageValidation = (file: File) => {
  const isInvalidExtension = invalidFileExtensions.some((ext) =>
    file.name.toLowerCase().endsWith(ext)
  );
  const isImageFormat = validImageTypes.includes(file.type);
  const isImageSize = file.size < maxImageSize;

  const errorMessage =
    !isImageFormat || isInvalidExtension
      ? "Неправильний формат зображення. Використовуйте PNG або JPEG"
      : "Неправильний розмір зображення. Максимальний розмір – 10 МБ";

  const isValidImage = isImageFormat && isImageSize;

  return { isValidImage, isInvalidExtension, errorMessage };
};

export const getYoutubeVideoId = (url: string): string | null => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  const videoIdGroupIndex = 2;
  const videoIdLength = 11;

  return match && match[videoIdGroupIndex].length === videoIdLength
    ? match[videoIdGroupIndex]
    : null;
};

export const checkedVrBundle = (bundles: ApplicationMainModelFile[]) => {
  return bundles?.some(
    (item) => item.type === ApplicationMainModelFileTypeEnum.Vr
  );
};

export const checkedOtherBundle = (bundles: ApplicationMainModelFile[]) => {
  return bundles?.some(
    (item) => item.type !== ApplicationMainModelFileTypeEnum.Vr
  );
};

export const getBundleStatus = (
  bundles: ApplicationMainModelFile[],
  name: BundleNames,
  type: BundleMode
) => {
  const isVrCurrentBundle = name === BundleNames.Vr;
  const isManifestBundleMode = type === BundleMode.Manifest;
  const isVrBundles = checkedVrBundle(bundles);
  const isOtherBundles = checkedOtherBundle(bundles);

  const isDisabledVrBundle =
    isVrCurrentBundle && isOtherBundles && !isManifestBundleMode;
  const isDisabledOtherBundles =
    !isVrCurrentBundle && isVrBundles && !isManifestBundleMode;

  return { isDisabledVrBundle, isDisabledOtherBundles };
};

export const mapIds = <T extends { id?: string }>(arr: T[] = []) => {
  return arr.map((item) => item.id);
};

export const validateColoringPageFile = (file: File): [boolean, string] => {
  const { type, size } = file;
  const sizeMb = size / 1024 / 1024;
  const isInvalidSize = sizeMb > coloringPageFileSize;
  const isValidType = acceptedColoringPageFileExtension.includes(
    type.split("/")[1]
  );

  if (isInvalidSize) return [true, wrongFileSizeMessage];
  if (!isValidType) return [true, wrongFileExtensionMessage];

  return [false, ""];
};

export const validatePrice = (value: string | undefined): [boolean, string] => {
  const isNumber = !isNaN(Number(value));

  if (value === undefined || !value.length) {
    return [true, "Это поле обязательное"];
  }

  if (!isNumber) {
    return [true, "Значение должно быть числом"];
  }

  const price = Number(value);
  if (price && value[0].startsWith("0")) {
    return [true, "Число не может начинаться с 0"];
  }
  if (price < 1) {
    return [true, "Число должно быть больше 0"];
  }
  if (price > MAX_GOODS_PRICE) {
    return [true, `Число не должно превышать ${MAX_GOODS_PRICE}`];
  }
  if (!Number.isInteger(price) || String(price) !== value) {
    return [true, "Число должно быть целым"];
  }

  return [false, ""];
};
