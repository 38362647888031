import { useReactiveVar } from "@apollo/client";
import { mobileAppModel } from "../../state";
import { Autocomplete } from "@material-ui/lab";
import { ExperimentTypeEnum } from "../../../../../generated/graphql";
import { ModalInput } from "../../../../shared/Style/Style";
import { ContentTagByContentType, experiments } from "../../constants";

export const SelectExperimentType = () => {
  const model = useReactiveVar(mobileAppModel.model);
  const isLoading = useReactiveVar(mobileAppModel.updateLoading);
  const inputErrors = useReactiveVar(mobileAppModel.inputErrors);

  if (!model) return null;
  const { id, experimentType, isUploadedToMarket } = model;

  const setExperimentTypeHandler = (
    experimentType: ExperimentTypeEnum | null
  ) => {
    if (experimentType) {
      mobileAppModel.updateExperimentType({
        setApplicationMainModelExperimentTypeId: id,
        experimentType,
      });
    } else {
      mobileAppModel.setExperimentType(experimentType);
    }

    mobileAppModel.cleanUpTags();
  };

  return (
    <>
      <Autocomplete
        disabled={isLoading || isUploadedToMarket}
        options={experiments.map((item) => item.type)}
        onChange={(_, value) =>
          setExperimentTypeHandler(value as ExperimentTypeEnum)
        }
        onFocus={() => mobileAppModel.setInputErrors("experimentType", false)}
        getOptionLabel={(option) =>
          experiments.find((item) => item.type === option)?.text
        }
        value={
          experiments.find((item) => item.type === experimentType)?.type || null
        }
        renderInput={(params) => (
          <ModalInput
            {...params}
            error={inputErrors.experimentType.error}
            label="Tип контента"
            required
            margin="normal"
          />
        )}
      />
    </>
  );
};
