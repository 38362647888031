import { FC, Dispatch, SetStateAction, useCallback } from 'react'
import { FiltersSelect } from '../shared/Selects/FiltersSelect'
import { SchoolsFiltersType } from './types'
import { endOfSubscriptionOptions, subscriptionTypesOptions, endOfSubscriptionOptionsMapper } from './constants'
import { FilterInput } from '../shared/Inputs/FilterInput'
import styled from 'styled-components'

interface SchoolsFiltersProps {
  setFilterVariables: Dispatch<SetStateAction<SchoolsFiltersType>>
}

export const SchoolsFilters: FC<SchoolsFiltersProps> = ({ setFilterVariables }) => {
  const handlePupilsCountFilter = useCallback(
    (value) => {
      setFilterVariables((prevState) => ({
        ...prevState,
        pupilsCount: value ? Number(value) : null,
      }))
    },
    [setFilterVariables]
  )

  const handleTeachersCountFilter = useCallback(
    (value) => {
      setFilterVariables((prevState) => ({
        ...prevState,
        teachersCount: value ? Number(value) : null,
      }))
    },
    [setFilterVariables]
  )

  const handleEndOfSubscriptionFilter = useCallback(
    (value) => {
      setFilterVariables((prevState) => ({
        ...prevState,
        daysRangeUntilLicenseExpiration: endOfSubscriptionOptionsMapper[value],
      }))
    },
    [setFilterVariables]
  )

  const handleSubscriptionTypeFilter = useCallback(
    (value) => {
      const isActiveSubscription = value ? value === 'ACTIVE' : null

      setFilterVariables((prevState) => ({
        ...prevState,
        isActiveSubscription,
      }))
    },
    [setFilterVariables]
  )

  return (
    <StyledSchoolsFiltersContainer>
      <FiltersSelect
        fieldsData={endOfSubscriptionOptions}
        handleSetFilter={handleEndOfSubscriptionFilter}
        overrideFormControlStyles={{ margin: 0 }}
      />
      <FiltersSelect
        fieldsData={subscriptionTypesOptions}
        handleSetFilter={handleSubscriptionTypeFilter}
        overrideFormControlStyles={{ width: 220 }}
      />
      <FilterInput
        title='Количество учеников'
        inputType='number'
        inputLabel='Макс. кол-во'
        inputPlaceholder='чел'
        handleApplyFilter={handlePupilsCountFilter}
        displayInputValueInTitle
        maxLength={9}
      />
      <FilterInput
        title='Количество учителей'
        inputType='number'
        inputLabel='Макс. кол-во'
        inputPlaceholder='чел'
        handleApplyFilter={handleTeachersCountFilter}
        displayInputValueInTitle
        maxLength={9}
      />
    </StyledSchoolsFiltersContainer>
  )
}

const StyledSchoolsFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`
