import { useCallback, ChangeEvent, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { blockNameText } from "../Courses/constants";
import { ModalInput } from "../../shared/Style/Style";
import { CourseItemType } from "../Courses/types";
import { getCourseById } from "../Courses/utils";

interface SelectCoursesProps {
  courses: CourseItemType[];
  activeCourse: CourseItemType;
  setCourseId: (courseId) => void;
}
export const SelectCourses = ({
  courses = [],
  activeCourse,
  setCourseId,
}: SelectCoursesProps) => {
  const defaultValue = courses[0].id;
  const getDataToRender = useCallback(
    (id: string) => {
      const { name } = getCourseById(courses, id);
      return name;
    },
    [courses]
  );
  const handleChange = (event: ChangeEvent<{}>, id?: string) => setCourseId(id);

  useEffect(() => {
    setCourseId(defaultValue);
  }, []);

  return (
    <Autocomplete
      filterSelectedOptions
      options={courses.map(({ id }) => id)}
      defaultValue={defaultValue}
      getOptionLabel={(id) => getDataToRender(id)}
      noOptionsText={"Курс с таким названием не найден"}
      clearText={"Убрать доступ к курсу"}
      disableClearable={!activeCourse?.courseIsAvailable}
      onChange={handleChange}
      renderInput={(params) => (
        <ModalInput label={blockNameText} variant="filled" {...params} />
      )}
    />
  );
};
