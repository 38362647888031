import React, { useState, useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";
import {
  CreateAppMainThemeBackgroundDocument,
  CreateAppMainThemeBackgroundMutation,
  DeleteAppMainThemeBackgroundDocument,
  DeleteAppMainThemeBackgroundMutation,
  GetAppMainThemeBackgroundsDocument,
  GetAppMainThemeBackgroundsQuery,
  UpdateAppMainThemeBackgroundDocument,
  UpdateAppMainThemeBackgroundMutation,
} from "../../../../generated/graphql";

import {  CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { LoadingBox } from "../../../shared/Style/Style";
import { SettingsList } from "../SettingsList";
import { SettingsCreateItemForm } from "../SettingsCreateItemForm";

import { Content } from "../Settings.style";

export const ThemeBackground = () => {
  const [inputError, setInputError] = useState(false);
  const [requestError, setRequestError] = useState(false);

  const [isCreating, setCreating] = useState(false);
  const [isEditing, setEditing] = useState({
    itemId: '',
    status: false,
  })

  const {
    data: backgroundsData,
    loading: backgroundsLoading,
    error: backgroundsError,
  } = useQuery<GetAppMainThemeBackgroundsQuery>(GetAppMainThemeBackgroundsDocument);

  const [
    createBackground,
    {
      data: createBackgroundData,
      loading: createBackgroundLoading,
      error: createBackgroundError,
    }
  ] = useMutation<CreateAppMainThemeBackgroundMutation>(
    CreateAppMainThemeBackgroundDocument,
    { refetchQueries: [{query: GetAppMainThemeBackgroundsDocument}] }
  );

  const createHandler = (name: string, fileIOS: File, fileAndroid: File,manifestIOS?:File,manifestAndroid?:File): void => {
    if (!name || !fileIOS || !fileAndroid) {
      setInputError(true);
      return;
    }

    createBackground({ variables: { name, fileIOS, fileAndroid, manifestIOS, manifestAndroid } })
  }

  const [
    updateBackground,
    {
      data: updateBackgroundData,
      loading: updateBackgroundLoading,
      error: updateBackgroundError,
    }
  ] = useMutation<UpdateAppMainThemeBackgroundMutation>(
    UpdateAppMainThemeBackgroundDocument,
    { refetchQueries: [{query: GetAppMainThemeBackgroundsDocument}] }
  );

  const updateHandler = (id: string, name: string, fileIOS?: File, fileAndroid?: File,manifestIOS?:File,manifestAndroid?:File): void => {
    if(!id || !name) {
      setInputError(true);
      return;
    }

    updateBackground({ variables: { id, name, fileIOS, fileAndroid , manifestIOS , manifestAndroid } })
  }

  const [
    deleteBackground,
    {
      data: deleteBackgroundData,
      loading: deleteBackgroundLoading,
      error: deleteBackgroundError,
    }
  ] = useMutation<DeleteAppMainThemeBackgroundMutation>(
    DeleteAppMainThemeBackgroundDocument,
    { refetchQueries: [{query: GetAppMainThemeBackgroundsDocument}] }
  );

  const deleteHandler = (id: string): void => {
    if(!id) {
      setInputError(true);
      return;
    }
    deleteBackground({ variables: { id } })
  }

  useEffect(() => {
    if(createBackgroundError || updateBackgroundError || deleteBackgroundError) {
      setRequestError(true);
    }
  }, [createBackgroundError, updateBackgroundError, deleteBackgroundError]);

  useEffect(() => {
    if(deleteBackgroundData || updateBackgroundData || createBackgroundData) {
      setRequestError(false);
    }
  }, [deleteBackgroundData, updateBackgroundData, createBackgroundData]);

  useEffect(() => {
    if(updateBackgroundData) {
      setEditing({
        itemId: '',
        status: false,
      });
    }

    if(createBackgroundData) {
      setCreating(false);
    }
  }, [updateBackgroundData, createBackgroundData]);

  const areEditingButtonsDisabled = createBackgroundLoading || updateBackgroundLoading || deleteBackgroundLoading || isCreating;
  const areCreatingButtonsDisabled = createBackgroundLoading || updateBackgroundLoading || deleteBackgroundLoading || isEditing.status;

  let content;

  if (backgroundsLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    )
  }

  if (backgroundsError) {
    content = <ErrorSnackbar error={backgroundsError}/>
  }

  if (backgroundsData) {
    const backgrounds = backgroundsData.getAppMainThemeBackgrounds;

    content = (
      <>
        <SettingsCreateItemForm
          header={'Фоны тем'}
          withMobileSystemFiles={true}
          createItem={createHandler}
          setCreating={setCreating}
          inputError={inputError}
          setInputError={setInputError}
          areButtonsDisabled={areCreatingButtonsDisabled}
          isRequestSuccessful={Boolean(createBackgroundData)}
        />

        <SettingsList
          withMobileSystemFiles={true}
          list={backgrounds}
          updateItem={updateHandler}
          deleteItem={deleteHandler}
          buttonsDisabled={areEditingButtonsDisabled}
          isEditing={isEditing}
          setEditing={setEditing}
        />
      </>
    )
  }

  return (
    <Content>
      {content}

      <Snackbar
        open={inputError}
        onClose={() => setInputError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          Вы ввели некорректное название
        </Alert>
      </Snackbar>

      <Snackbar
        open={requestError}
        onClose={() => setRequestError(false)}
        autoHideDuration={6000}
      >
        <Alert severity="error">
          {createBackgroundError?.message || updateBackgroundError?.message || deleteBackgroundError?.message}
        </Alert>
      </Snackbar>
    </Content>
  )
}
