import React, { useEffect, useState } from "react";
import {
  TableHead,
  Table,
  TableBody,
  TableRow,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { CustomTable } from "../../../shared/CustomTable";
import {
  CustomTableCell,
  CustomTableHeaderCell,
  CustomTableInputWithoutBorders,
  NoItemsPlaceholder,
} from "../../../shared/CustomTable/CustomTable.style";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  EmployeesDistribution,
  GetEmployeesDistributionLightDocument,
  GetEmployeesDistributionLightQuery,
  GetEmployeesDistributionsByNameDocument,
  GetEmployeesDistributionsByNameQuery,
  UpdateEmployeesDistributionMutation,
  UpdateEmployeesDistributionDocument,
  CreateEmployeesDistributionMutation,
  CreateEmployeesDistributionDocument,
  DeleteEmployeesDistributionDocument,
  DeleteEmployeesDistributionMutation,
} from "../../../../generated/graphql";
import { ErrorSnackbar } from "../../../shared/Error/ErrorSnackbar";
import { ButtonPlus, LoadingBox } from "../../../shared/Style/Style";
import { MonthNavigationBar } from "../../../shared/MonthNavigationBar";
import { CreatButton } from "../../Reports.style";
import { CreateDistributionForm } from "./CreateDistributionForm";
import { Chart } from 'chart.js';
import { Pie } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { chartColors } from "../../../../constants/chartColor";
import { CreateVacancyPlanModal } from "../VacancyReport/CreateVacancyPlanModal";
import DeleteIcon from "@material-ui/icons/Delete";
Chart.register(ChartDataLabels);

const pieOptions = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        color: '#000',
        font: {
          weight: 'bold',
          size: 30,
          color: '#fff'
        }
      },
      datalabels: {
        formatter: (value: any, ctx: any) => {
          return ctx.chart.data.labels[ctx.dataIndex]
        },
        color: '#000',
        anchor: "center",
        textAlign: "end",
        align: "end",
        font: {
          size: '11px',
        },
      },
    },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

export interface ICreateData {
  [key: string]: string | number;
}

const headers = ["Проекты", "Количество сотрудников на проекте", "Тип проекта"];

export const EmployeesDistributionReport = ({
  reports,
}: {
  reports: boolean;
}) => {
  const [months, setMonths] = useState<{ id: string; name: string }[]>([]);
  const [newMonth, setNewMonth] = useState<boolean>(false);
  const [chosenMonth, setChosenMonth] = useState<string>("");
  const [editedDistribution, setEditedDistribution] = useState<{
    id: string;
    employeesNumber: number;
  }>({
    id: "",
    employeesNumber: 0,
  });

  const [isCreatingDistribution, setCreatingDistribution] = useState<{
    status: boolean;
    data: ICreateData;
  }>({
    status: false,
    data: {
      projectId: "",
      employeesNumber: 0,
    },
  });

  const [inputErrors, setInputErrors] = useState({
    projectId: false,
  });

  const {
    data: employeesDistributionsData,
    loading: employeesDistributionsLoading,
    error: employeesDistributionsError,
  } = useQuery<GetEmployeesDistributionLightQuery>(
    GetEmployeesDistributionLightDocument
  );

  const [
    getMonth,
    { data: monthData, loading: monthLoading, error: monthError },
  ] = useLazyQuery<GetEmployeesDistributionsByNameQuery>(
    GetEmployeesDistributionsByNameDocument,
    { variables: { name: chosenMonth } }
  );

  const [
    createDistribution,
    {
      data: createDistributionData,
      loading: createDistributionLoading,
      error: createDistributionError,
    },
  ] = useMutation<CreateEmployeesDistributionMutation>(
    CreateEmployeesDistributionDocument,
    {
      refetchQueries: [
        {
          query: GetEmployeesDistributionsByNameDocument,
          variables: { name: chosenMonth },
        },
      ],
    }
  );

  const [
    deleteDistribution,
    {
      data: deleteDistributionData,
      loading: deleteDistributionLoading,
      error: deleteDistributionError,
    },
  ] = useMutation<DeleteEmployeesDistributionMutation>(
    DeleteEmployeesDistributionDocument,
    {
      refetchQueries: [
        {
          query: GetEmployeesDistributionsByNameDocument,
          variables: { name: chosenMonth },
        },
      ],
    }
  );

  const createDistributionHandler = () => {
    if (!isCreatingDistribution.data.projectId) {
      setInputErrors((state) => ({
        ...state,
        projectName: true,
      }));
    }

    if (!isCreatingDistribution.data.projectId) {
      return;
    }

    createDistribution({
      variables: {
        distributionData: { name: chosenMonth, ...isCreatingDistribution.data },
      },
    });
  };

  const [
    updateDistribution,
    {
      data: updateDistributionData,
      loading: updateDistributionLoading,
      error: updateDistributionError,
    },
  ] = useMutation<UpdateEmployeesDistributionMutation>(
    UpdateEmployeesDistributionDocument,
    {
      refetchQueries: [
        {
          query: GetEmployeesDistributionsByNameDocument,
          variables: { name: chosenMonth },
        },
      ],
    }
  );

  useEffect(() => {
    const data = employeesDistributionsData?.getEmployeesDistributionLight;
    data &&
      setMonths([...data.map((data) => ({ id: data.name, name: data.name }))]);
  }, [employeesDistributionsData?.getEmployeesDistributionLight?.length]);

  useEffect(() => {
    if (months?.length) {
      setChosenMonth(months[months.length - 1].name);
    }
  }, [months?.length]);

  useEffect(() => {
    if (chosenMonth) {
      getMonth();
    }
  }, [chosenMonth]);

  useEffect(() => {
    if (updateDistributionData) {
      setEditedDistribution({
        id: "",
        employeesNumber: 0,
      });
    }
  }, [updateDistributionData]);

  useEffect(() => {
    if (createDistributionData) {
      setCreatingDistribution({
        status: false,
        data: {
          employeesNumber: 0,
        },
      });
    }
  }, [createDistributionData]);

  let employeesChartContent;
  let projectTypeChartContent;
  let content;

  if (employeesDistributionsLoading || monthLoading) {
    content = (
      <LoadingBox>
        <CircularProgress color="inherit" />
      </LoadingBox>
    );
  }

  if (
    employeesDistributionsError ||
    monthError ||
    createDistributionError ||
    updateDistributionError ||
    deleteDistributionError
  ) {
    content = (
      <ErrorSnackbar
        error={
          employeesDistributionsError ||
          monthError ||
          createDistributionError ||
          updateDistributionError ||
          deleteDistributionError
        }
      />
    );
  }

  if (employeesDistributionsData && monthData) {
    const distributions =
      monthData.getEmployeesDistributionsByName.distributions;

    const existingProjects = distributions.map(
      ({ projectName }) => projectName
    );

    const employeesReport =
      monthData.getEmployeesDistributionsByName.additionalData.employeesReport;
    const statusReport =
      monthData.getEmployeesDistributionsByName.additionalData.statusReport;
//${el.employeesPercent}%
    const labels = employeesReport.map(
      (el) => `${el.projectName}`
    );
    const projectStatusLabels = statusReport.map(
      (el) => `${el.projectsTypesPercent}% ${el.status}`
    );

    const totalEmployees = distributions.reduce(
      (acc, item) => acc + item.employeesNumber,
      0
    );

    const employeesPercents = employeesReport.map((el) => el.employeesPercent);
    const projectsPercents = statusReport.map((el) => el.projectsTypesPercent);

    employeesChartContent = (
      <div
        style={{
          width: "60%",
          height: "60%",
          // marginTop: '3rem'
        }}
      >
        {/*<Doughnut*/}
        {/*  data={{*/}
        {/*    maintainAspectRatio: false,*/}
        {/*    responsive: false,*/}
        {/*    labels: labels,*/}
        {/*    datasets: [*/}
        {/*      {*/}
        {/*        data: employeesPercents,*/}
        {/*        backgroundColor: chartColors,*/}
        {/*        hoverBackgroundColor: chartColors,*/}
        {/*      },*/}
        {/*    ],*/}
        {/*  }}*/}
        {/*  options={pieOptions}*/}
        {/*  ref={(input) => {*/}
        {/*    chartInstance = input;*/}
        {/*  }}*/}
        {/*/>*/}
        <Pie
          data={{
            maintainAspectRatio: false,
            responsive: false,
            labels: labels,
            datasets: [
              {
                data: employeesPercents,
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
              },
            ],
          }}
          options={pieOptions}
          ref={(input) => {
            chartInstance = input;
          }}
        />
      </div>
    );
    projectTypeChartContent = (
      <div
        style={{
          width: "70%",
          height: "70%",
          marginTop: '1rem'
        }}
      >
        <Pie
          data={{
            maintainAspectRatio: false,
            responsive: false,
            labels: projectStatusLabels,
            datasets: [
              {
                data: projectsPercents,
                backgroundColor: chartColors,
                hoverBackgroundColor: chartColors,
              },
            ],
          }}
          options={pieOptions}
          ref={(input) => {
            chartInstance = input;
          }}
        />
      </div>
    );

    content = (
      <>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <CustomTableHeaderCell key={header}>
                  {header}
                </CustomTableHeaderCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!reports && (
              <TableRow>
                <CustomTableCell colSpan={3} padding="none">
                  <CreatButton
                    onClick={() =>
                      isCreatingDistribution.status
                        ? createDistributionHandler()
                        : setCreatingDistribution({
                            ...isCreatingDistribution,
                            status: true,
                          })
                    }
                  >
                    {isCreatingDistribution.status ? "Сохранить" : "Добавить"}
                  </CreatButton>
                </CustomTableCell>
              </TableRow>
            )}

            {isCreatingDistribution.status && (
              <CreateDistributionForm
                data={isCreatingDistribution.data}
                existingProjects={existingProjects}
                errors={inputErrors}
                setCreatingDistribution={setCreatingDistribution}
              />
            )}

            {distributions.map((distribution) => (
              <TableRow key={distribution.id}>
                <CustomTableCell>{distribution.projectName}</CustomTableCell>

                <CustomTableCell
                  padding="none"
                  onClick={() => {
                    if (
                      editedDistribution.id === distribution.id ||
                      updateDistributionLoading ||
                      createDistributionLoading ||
                      deleteDistributionLoading
                    ) {
                      return;
                    }

                    setEditedDistribution({
                      id: distribution.id,
                      employeesNumber: distribution.employeesNumber,
                    });
                  }}
                >
                  <CustomTableInputWithoutBorders
                    type="number"
                    disabled={
                      updateDistributionLoading ||
                      createDistributionLoading ||
                      isCreatingDistribution.status
                    }
                    value={
                      editedDistribution.id === distribution.id
                        ? editedDistribution.employeesNumber
                        : distribution.employeesNumber
                    }
                    onFocus={() =>
                      setEditedDistribution({
                        id: distribution.id,
                        employeesNumber: distribution.employeesNumber,
                      })
                    }
                    onChange={({ target }) =>
                      setEditedDistribution({
                        ...editedDistribution,
                        employeesNumber: +target.value,
                      })
                    }
                    onBlur={() =>
                      updateDistribution({
                        variables: { distributionData: editedDistribution },
                      })
                    }
                  />
                </CustomTableCell>

                <CustomTableCell>
                  <Box style={{ position: "relative" }}>
                    {distribution?.projectType?.name}
                    {!reports &&         <Box
                      style={{
                        position: "absolute",
                        right: "-10px",
                        top: "0px",
                      }}
                    >
                      <DeleteIcon
                        style={{ color: "#E85050" }}
                        onClick={() => {
                          deleteDistribution({
                            variables: { id: distribution.id },
                          });
                        }}
                      />
                    </Box>}
                  </Box>
                </CustomTableCell>
              </TableRow>
            ))}
            {distributions.length && (
              <TableRow>
                <CustomTableCell style={{ backgroundColor: "#9AD898" }}>
                  Всего трудовых ресурсов
                </CustomTableCell>
                <CustomTableCell style={{ backgroundColor: "#9AD898" }}>
                  {totalEmployees}
                </CustomTableCell>
                <CustomTableCell style={{ backgroundColor: "#9AD898" }} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        {!distributions.length && (
          <NoItemsPlaceholder>Отчеты отсутствуют</NoItemsPlaceholder>
        )}
      </>
    );
  }

  const tableData =
    monthData?.getEmployeesDistributionsByName as EmployeesDistribution[];

  let chartInstance = null;

  return (
    <>
      {!reports && (
        <Box mb={5} mt={4} textAlign="right">
          <ButtonPlus
            width={180}
            variant="contained"
            onClick={() => setNewMonth(true)}
          >
            Добавить
          </ButtonPlus>
        </Box>
      )}
      <CustomTable data={tableData} tableContent={content} />
      <MonthNavigationBar
        field="name"
        months={months || []}
        chosenMonth={chosenMonth}
        setChosenMonth={setChosenMonth}
      />
      {reports && employeesDistributionsData?.getEmployeesDistributionLight && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {employeesChartContent}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {projectTypeChartContent}
          </div>
        </>
      )}

      {newMonth && (
        <CreateVacancyPlanModal
          open={newMonth}
          setOpen={() => setNewMonth(false)}
          setMonths={setMonths}
          months={months}
        />
      )}
    </>
  );
};
