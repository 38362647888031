import { useReactiveVar } from "@apollo/client";
import { ContentTag } from "../../../../../generated/graphql";
import { mobileAppModel } from "../../state";
import { ModalInput } from "../../../../shared/Style/Style";
import { Chip, CircularProgress, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { Autocomplete } from "@material-ui/lab";
import { ContentTagByContentType } from "../../constants";

export const SelectTagCategory = () => {
  const model = useReactiveVar(mobileAppModel.model);
  const tagsList = useReactiveVar(mobileAppModel.tagsList);
  const isLoading = useReactiveVar(mobileAppModel.updateLoading);
  const isTagsLoading = useReactiveVar(mobileAppModel.isTagsLoading);
  const inputErrors = useReactiveVar(mobileAppModel.inputErrors);
  
  if (!model) return null;
  const { tags, experimentType, isUploadedToMarket } = model;

  const getTagToRender = (id: string) => {
    return findTagById(tagsList, id)?.displayName || "";
  };

  const findTagById = (tags: ContentTag[], id: string) => {
    return tags.find((tag) => tag.id === id);
  };

  const handleFetchTagsByExperimentType = async () => {
    if (experimentType) {
      await mobileAppModel.getContentTag([ContentTagByContentType.get(experimentType)]);
    }
  }

  const handleRemoveTagIds = (tagId: string) => {
    const tagsToSet =
      tags.filter(({ id }) => id !== tagId).map(({ id }) => id) || [];
    mobileAppModel.setTags(tagsToSet);
  };

  const deleteIcon = (
    <IconButton>
      <CancelIcon />
    </IconButton>
  );

  return (
    <>
      <Autocomplete
        multiple
        disabled={isLoading || isUploadedToMarket}
        loading={isTagsLoading}
        loadingText={<CircularProgress color="inherit" size={20} />}
        filterSelectedOptions
        style={{ maxWidth: "520px", marginBottom: "20px", minHeight: "60px" }}
        options={tagsList?.map(({ id }) => id)}
        onChange={(_, value) => {
          mobileAppModel.setTags(value);
        }}
        onFocus={() => mobileAppModel.setInputErrors("tags", false)}
        onOpen={() => handleFetchTagsByExperimentType()}
        getOptionLabel={(option) => {
          return getTagToRender(option);
        }}
        value={tags?.map(({ id }) => id)}
        renderTags={(value: string[], getTagProps) => {
          return value.map((tagId: string, index: number) => (
            <Chip
              variant="default"
              label={findTagById(tags, tagId)?.displayName || ""}
              deleteIcon={deleteIcon}
              {...getTagProps({ index })}
              onDelete={() => handleRemoveTagIds(tagId)}
            />
          ));
        }}
        renderInput={(params) => (
          <ModalInput
            required
            error={inputErrors.tags.error}
            label="Теги"
            margin="none"
            {...params}
          />
        )}
      />
    </>
  );
};
