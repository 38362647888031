import React from "react";
import { SettingsEmptyListPlaceholder, SettingsItemsList } from "../Settings.style";
import { SettingsListItem } from "../SettingsListItem";

interface ISettingsListProps {
  list: any[];
  updateItem(id: string, name: string, file?: File): void;
  deleteItem(id: string): void;
  buttonsDisabled: boolean;
  isEditing: {
    itemId: string;
    status: boolean;
  };
  setEditing(state: {
    itemId: string;
    status: boolean;
  }): void;
  withFile?: boolean;
  withMobileSystemFiles?: boolean;
}

export const SettingsList = ({
  list,
  updateItem,
  deleteItem,
  buttonsDisabled,
  isEditing,
  setEditing,
  withFile,
  withMobileSystemFiles
}: ISettingsListProps) => {
  return !!list.length
    ? (
      <SettingsItemsList>
        {list.map(item => (
          <SettingsListItem
            key={item.id}
            item={item}
            updateItem={updateItem}
            deleteItem={deleteItem}
            buttonsDisabled={buttonsDisabled}
            isEditing={isEditing}
            setEditing={setEditing}
            withFile={withFile}
            withMobileSystemFiles={withMobileSystemFiles}
          />
        ))}
      </SettingsItemsList>
    )
    : (
      <SettingsItemsList>
        <SettingsEmptyListPlaceholder>
          Список пуст. Введите название и нажмите кнопку добавить для добавления элементов.
        </SettingsEmptyListPlaceholder>
      </SettingsItemsList>
    )
}