import { useState, ChangeEvent } from "react";
import { Tabs } from "@material-ui/core";
import { CustomTab, TabIndicatorProps } from "./Styles";

interface TabList {
  tabName: string;
  TabPanel: JSX.Element;
}
interface BasicTabsProps {
  tabList?: TabList[];
}
export const BasicTabs = ({ tabList }: BasicTabsProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  if (!tabList) return null;

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={TabIndicatorProps}
      >
        {tabList.map(({ tabName }, index) => (
          <CustomTab key={tabName} label={tabName} $active={value === index} />
        ))}
      </Tabs>
      {tabList.map(({ tabName, TabPanel }, index) => (
        <div key={tabName} hidden={value !== index}>
          {value === index && TabPanel}
        </div>
      ))}
    </>
  );
};
