import { FC, HTMLInputTypeAttribute, useEffect, useState } from 'react'
import { StyledFilterSelectList } from '../Style/Style'
import { FormControl, InputLabel, TextField } from '@material-ui/core'
import styled from 'styled-components'
import { useDebounce } from '../Utils/OtherOperations/useDebounce'

interface FilterInputProps {
  title: string
  inputLabel: string
  handleApplyFilter: (value: unknown) => void
  inputType?: HTMLInputTypeAttribute
  inputPlaceholder?: string
  displayInputValueInTitle?: boolean
  maxLength?: number
}

export const FilterInput: FC<FilterInputProps> = ({
  title,
  inputLabel,
  inputType,
  inputPlaceholder,
  handleApplyFilter,
  displayInputValueInTitle,
  maxLength,
}) => {
  const [value, setValue] = useState<unknown>(null)

  const debouncedValue = useDebounce(value, 500)

  useEffect(() => {
    handleApplyFilter(debouncedValue)
  }, [debouncedValue])

  const handleChange = (event) => {
    const { value } = event.target
    if (maxLength && value.length > maxLength) return
    setValue(value)
  }

  return (
    <StyledFilterInputFormControl>
      <StyledFilterSelectList
        value
        renderValue={(v) =>
          displayInputValueInTitle ? <span>{value ? `${title}: ${value}` : title}</span> : <span>{title}</span>
        }
        // display input option at the bottom
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        <StyledTextFieldContainer>
          <InputLabel>{inputLabel}:</InputLabel>
          <TextField
            type={inputType}
            value={value}
            placeholder={inputPlaceholder}
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            inputProps={{ min: 0 }}
            onChange={handleChange}
          />
        </StyledTextFieldContainer>
      </StyledFilterSelectList>
    </StyledFilterInputFormControl>
  )
}

export const StyledFilterInputFormControl = styled(FormControl)`
  border-radius: 2px;
  background-color: #b3b3b3;
  width: 260px;
  height: 35px;
  margin-left: 20px;
  & .MuiInput-underline:before,
  & .MuiInput-underline:after {
    all: unset;
    &:focus,
    &:active,
    &:hover {
      all: unset;
    }
  }
`

const StyledTextFieldContainer = styled.div`
  display: flex;
  width: 260px;
  padding: 10px 20px;

  label {
    margin-right: 15px;
  }
`
