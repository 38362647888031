import React, { FC, useEffect, useMemo, useRef } from 'react';
import './chartConfig';
import { options, getChartData } from './chartConfig';
import { Bar } from 'react-chartjs-2';
import { ChartDataType, ChartHeaderType, ChartInfoMessageDataType } from '../types';
import { Typography } from '@material-ui/core';
import { LoadingProgress } from '../../shared/LoadingProgress';
import { VerticalBarChartFilterProps, VerticalBarChartFilter } from '../VerticalBarChartFilter/VerticalBarChartFilter';
import { VerticalBarChartContainer } from '../VerticalBarChartContainer/VerticalBarChartContainer';
import { VerticalChartBarContainer, VerticalChartBarContainerBody } from '../../shared/Style/Style';
import { getNewWidth } from '../utils';


interface VerticalBarChartProps {
  chartHeader: ChartHeaderType;
  chart: ChartDataType;
  chartFilter: VerticalBarChartFilterProps;
  isLoadingProgress: boolean;
  message:  ChartInfoMessageDataType;
}

export const VerticalBarChart: FC<VerticalBarChartProps> = ({
  chartHeader,
  chart,
  chartFilter,
  isLoadingProgress,
  message,
}) => {

  const { text, isChartDisplay } = message;
  const dataChart = useMemo(() => getChartData(chart), [chart]);
  const chartContainerBody = useRef(null);
  const totalLabel = chart.label && chart.label.length;
  const newWidth = getNewWidth(totalLabel);
  
  useEffect(() => { if (chartContainerBody.current)  { chartContainerBody.current.style.width = `${newWidth}px`; }}, [dataChart]);

  const ChartBody = (
    <div> 
      <Typography variant="h6" align='center'>{text}</Typography>
      {isChartDisplay &&
        <VerticalChartBarContainer>
          <VerticalChartBarContainerBody ref={chartContainerBody}>
            <Bar options={options} data={dataChart} />
          </VerticalChartBarContainerBody>
        </VerticalChartBarContainer>
      }
    </div>
  );

  const VerticalChart = !isLoadingProgress ? ChartBody : <LoadingProgress />;

  return (
    <VerticalBarChartContainer {...chartHeader}>

      <VerticalBarChartFilter {...chartFilter} />

      {VerticalChart}

    </VerticalBarChartContainer>
  );
};