import { Typography } from '@material-ui/core';
import {VerticalChartContainer, VerticalChartQuantity, VerticalChartSubTitle, VerticalChartTitle} from '../../shared/Style/Style';

interface VerticalBarChartContainerProps {
    chartTitle: string;
    chartSubtitle: string;
    totalValue: string;
    children?: React.ReactNode | React.ReactNode[] | null;
};

export const VerticalBarChartContainer:React.FC<VerticalBarChartContainerProps> = ({
    chartTitle,
    chartSubtitle,
    totalValue,
    children
}) => {

    return (
        <VerticalChartContainer>
            <VerticalChartTitle><Typography variant="subtitle1" align="center">{chartTitle}</Typography></VerticalChartTitle>
            <VerticalChartSubTitle><Typography variant="h6">{chartSubtitle}</Typography></VerticalChartSubTitle >
            <VerticalChartQuantity><Typography variant="h3">{totalValue}</Typography></VerticalChartQuantity >
            {children}
        </VerticalChartContainer>
    )
};